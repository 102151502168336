export enum CorrectionsAuditorRemarksFormEnum {
    authorityRemarks = 'authorityRemarks',
    auditorComments = 'auditorComments',
    doNotSetInvoiceAuditor = 'doNotSetInvoiceAuditor',
    manualRateInd = 'manualRateInd',
};

export enum CorrectionsAuditorRemarksFormLabel {
    authorityRemarks = 'Authority Remarks (Printed on the Invoice)',
    auditorComments = 'Auditor Comments (Not Printed on the Invoice)',
    doNotSetInvoiceAuditor = 'Do Not Send Invoice',
    autoRateOpt = 'Auto-Rate',
    manualRateInd = 'Manual-Rate',
    autoManualRateIndErr = 'manualAutoRateOptErr',
};

export enum CorrectionsAuditorRemarksValues {
    autoRate = 'autoRate',
    manualRate = 'manualRate'
};

export enum CorrectionsAuditorRemarksFormTitles {
    title = 'Auditor Remarks',
    titleInvoicing = 'Invoicing'
};


export enum CorrectionsAuditorRemarksFormIdsEnum {
  manualAutoRateOptBtn = 'manualAutoRateOptBtn',
  optionsSection = 'optionsSection',
};
