import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { FormSubmitValidationsModel } from '@shared/models/corrections/corrections-form/form-submit-validations.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { AccessorialValidatorsService } from '../accessorials-validators.service';
import { AuditorRemarksValidatorsService } from '../auditor-remarks/auditor-remarks-validators.service';

@Injectable()
export class CorrectionsFormSubmitValidatorsService {

  constructor(
    private accessorialValidatorsService: AccessorialValidatorsService,
    private auditorRemarksValidatorsService: AuditorRemarksValidatorsService,
  ) { }

  submitValidators(params: FormSubmitValidationsModel): ErrorModel[] {
    const acErrors = this.acsValidator(params);
    const auditorCommentsErrors = this.auditorRemarks(params);
    return [...acErrors, ...auditorCommentsErrors];
  }

  private acsValidator(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const duplicatedAcErrors = requestActionType !== RequestActionTypeCd.notApproved ? this.accessorialValidatorsService.duplicateAcCodeValidator(form) : [];
    return duplicatedAcErrors;
  }

  private auditorRemarks(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const requiredAuditorComments = requestActionType === RequestActionTypeCd.notApproved
      ? this.auditorRemarksValidatorsService.auditorCommentsRequired(form)
      : [];
    return requiredAuditorComments;
  }
}
